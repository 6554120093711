





































































/* eslint-disable camelcase */
import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '../../types';

interface Validation {
  [index: string]: string|null,
  new_password: string|null,
  verify_password: string|null,
}

interface VuexBindings {
  account: ApiResource.Account,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  data() {
    return {
      error: null,
      form: {
        new_password: '',
        verify_password: '',
      },
      isSubmitting: false,
      showModal: false,
      validation: {
        new_password: null,
        verify_password: null,
      } as Validation,
    };
  },
  computed: {
    ...mapGetters('account', {
      account: 'Account',
    }),
  },
  methods: {
    submit(): void {
      if (this.validate()) return;

      this.isSubmitting = true;
      this.showModal = true;
    },
    async handleAttempt(attempt: any): Promise<void> {
      this.handleModalClose();

      if (this.validate()) return;

      this.isSubmitting = true;

      await this.$store.dispatch('account/ChangePassword', {
        form: {
          new_password: this.form.new_password,
        },
        TOKEN: `${attempt.id}${attempt.secret}`,
      }).then(() => {
        this.$toastSuccess(this.$t('success-message').toString());
      }).catch((e) => {
        this.$et(e);
        this.validation = e.response?.data?.error?.fields.name;
      });

      this.isSubmitting = false;

      this.form.new_password = '';
      this.form.verify_password = '';
    },
    handleModalClose(): void {
      this.isSubmitting = false;
      this.showModal = false;
    },
    validate(validation: Validation = {
      new_password: null,
      verify_password: null,
    }): boolean {
      const { form: F } = this;
      const v: Validation = { ...validation };

      if (!F.new_password) {
        v.new_password = 'notOptional';
      }

      if (!F.verify_password) {
        v.verify_password = 'notOptional';
      } else if (F.verify_password !== F.new_password) {
        v.verify_password = 'identical';
      }

      this.validation = v;
      // Check if all keys of v are null, will return false(=!true)
      return !Object.keys(v).every((k) => v[k] === null);
    },
  },
});
