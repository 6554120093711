










import Vue from 'vue';
import PasswordForm from '@/components/account/PasswordForm.vue';

export default Vue.extend({
  components: {
    PasswordForm,
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
